import { axiosInstance, oauth_client_id } from './apiInstance';
import { api } from 'config/variables';
import {
	setClientDetails,
	setUser,
	setPermissions,
	set403Error,
	setSecureShipmentDetailsList,
} from 'actions/user';
import axios from 'axios';
import { createCookie, eraseCookie, reloadUser } from 'utils/functions';
import get from 'lodash/get';
import * as urls from './urls';

export const revokeConsumerTokenOAuth = (accessToken) => {
	let body = JSON.stringify({
		client_id: oauth_client_id,
		token: accessToken,
	});
	return () => {
		return axios
			.post(urls.revokeToken, body)
			.catch((err) => {
				console.log({ err });
				throw err;
			})
			.then((response) => {
				setClientDetails({});
				eraseCookie('client');
				eraseCookie('bearer');
				eraseCookie('expires');
				if (response.status !== 200) {
					let err = new Error(response.data.msg);
					console.log({ err });
					throw err;
				}
			});
	};
};

export const getConsumerToken = (obj) => {
	let body = JSON.stringify(obj);
	return () => {
		return axiosInstance
			.post(urls.getConsumerToken, body)
			.catch((err) => {
				console.log({ err });
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data.token) {
					return response.data.token;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getClientList = (
	status = 'active',
	search = '',
	is_on_advanced_plan = false
) => {
	return (dispatch) => {
		let params = {
			status,
			search,
			all_records: true,
		};
		if (is_on_advanced_plan) {
			params.rb_plan_name = 1;
		}
		return axiosInstance
			.get(urls.getClientList, {
				params,
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
export const getUsersByRole = (role) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getUserByRole, {
				params: {
					role,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
export const getClientDetails = (token, expires_in) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getCurrentUser, {
				headers: {
					Authorization: 'token ' + token,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				let errMsg = get(err, 'response.data', false);
				if (
					errMsg?.detail !==
						'Please accept agreement terms to proceed further' &&
					errMsg?.detail !== 'Auto Assign Carrier is required' &&
					(errResponse === 401 || errResponse === 403)
				) {
					eraseCookie('bearer');
					eraseCookie('expires');
					eraseCookie('client');
					console.log(api, errMsg);
					dispatch(
						set403Error({
							error403: true,
							error403Data: errMsg,
						})
					);
					// alert(errMsg, { okButton: ' 戻る ' });
					// window.location.replace(`${api.sso_url}/new-kyc`);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					dispatch(setPermissions(response.data.permissions));
					let clientData = response.data;
					delete clientData.permissions;
					delete clientData.agreements_accepted;
					delete clientData.groups;
					let clientDetails = JSON.stringify(clientData);
					createCookie('client', clientDetails, true, expires_in, '/');
					setClientDetails({});
					dispatch(
						set403Error({
							error403: false,
							error403Data: {},
						})
					);
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const userKYC = (reqObj) => {
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createKYC, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const clientSecureShipmentDetails = (reqObj) => {
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.clientSecureShipmentDetails, body)
			.catch((err) => {
				console.log('err', err);
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getAllClientSecureShipmentDetails = (
	// role,
	pageNo,
	query,
	fetchAll
) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getAllClientSecureShipmentDetails, {
				params: {
					page: pageNo || undefined,
					all_records: fetchAll || undefined,
					q: query || undefined,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					dispatch(setSecureShipmentDetailsList(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getClientSecureShipmentDetails = (client__id) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getClientSecureShipmentDetails, {
				params: {
					client__id,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
export const getSelectedClientDetails = (client__id) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getSelectedClientDetails, {
				params: {
					client__id,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const profile = (reqObj) => {
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.put(`${urls.profile}${reqObj.client_id}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response;
				} else {
					let err = new Error(response?.data?.msg);
					throw err;
				}
			});
	};
};

export const optSecureShipment = (reqObj) => {
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.put(`${urls.optSecureShipment}${reqObj.client_id}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response;
				} else {
					let err = new Error(response?.data?.msg);
					throw err;
				}
			});
	};
};

export const getKYCList = (page = undefined) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getKYCList, {
				params: {
					page,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const addClient = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createClient, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const userAgreement = (reqObj) => {
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.acceptAgreement, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const UserBankDetails = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getClientBankDetails)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getUserCarriers = (client_id) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.userCarriers, {
				params: {
					disable_carriers: true,
					client_id: client_id,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const createDisableCarrier = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.userCarriers, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if ((response.status === 201) | (response.status === 200)) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deleteDisableCarrier = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.delete(urls.userCarriers, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					console.log(response);
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const generateApiToken = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.generateApiToken, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					console.log(response);
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
export const getApiToken = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.generateApiToken)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					console.log(response);
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const validateCouponApi = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.getCoupon, body)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					console.log(response);
					let err = new Error(response);
					throw err;
				}
			});
	};
};

export const getCouponApi = (clientId, token) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getCoupon, {
				params: {
					client_id: clientId,
				},
				headers: {
					Authorization: 'token' + token,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				console.log('coupon ka error', err);
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getClientUsers = (token) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getClientUsers, {
				headers: {
					Authorization: 'token ' + token,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				let errMsg = get(err, 'response.data', false);
				if (
					errMsg?.detail !==
						'Please accept agreement terms to proceed further' &&
					errMsg?.detail !== 'Auto Assign Carrier is required' &&
					(errResponse === 401 || errResponse === 403)
				) {
					eraseCookie('bearer');
					eraseCookie('expires');
					eraseCookie('client');
					console.log(api, errMsg);
					dispatch(
						set403Error({
							error403: true,
							error403Data: errMsg,
						})
					);
					// alert(errMsg, { okButton: ' 戻る ' });
					// window.location.replace(`${api.sso_url}/new-kyc`);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const postQuickThreeWheelerLogApi = (reqObj) => {
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.quickLogApi, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response;
				} else {
					let err = new Error(response?.data?.msg);
					throw err;
				}
			});
	};
};
