/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
	{
		token ? <Redirect from="/auth/login" to="/client/dashboard" /> : <Redirect to="/auth/login-page" />
	}
*/
window.process = {};
import React, { Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import createStore from 'store/createStore';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import ScrollToTop from './ScrollTop';
import 'babel-polyfill';
import { api } from 'config/variables';
import { readCookie, createCookie } from 'utils/functions';
import Loader from 'components/Loader/Loader';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';
import './i18n';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Error403 from 'components/Error403Modal';
import AssignCarrierPopup from 'components/AssignCarrierPopup';
// import useCurrentWidth from 'hooks/useCurrentWidth';
import Intercom from '@intercom/messenger-js-sdk';
import get from 'lodash/get';
//Set your APP_ID
var APP_ID = 'ulrpck2i'; //your intercom id

const initializeIntercom = (user) => {
	const crypto = require('crypto');

	const secretKey = 'NCw2zZ7slnMAH6xABli9fPCnpB_xB9hghryj-oqC'; // IMPORTANT: your web Identity Verification secret key - keep it safe!
	const userIdentifier = user?.id ? user.id.toString() : ''; // IMPORTANT: a UUID to identify your user

	const hash = crypto
		.createHmac('sha256', secretKey)
		.update(userIdentifier)
		.digest('hex');
	Intercom({
		api_base: 'https://api-iam.intercom.io',
		app_id: APP_ID,
		user_id: user.id,
		user_hash: hash,
		name: user?.firstName || null,
		email: user?.email || null,
		Phone: user?.contact || null,
		First_Shipment_Date: user?.first_shipment_date || null,
		First_Recharge_Amount: user?.first_recharge_amount || null,
		First_Recharge_Date: user?.first_recharge_date || null,
		Last_Recharge_Amount: user?.last_recharge_amount || null,
		Last_Recharge_Date: user?.last_recharge_date || null,
		Last_Shipment_Date: user?.last_shipment_date || null,
		Last_Login: user?.last_login || null,
		alignment: 'right', // Change alignment as needed
		// Enable Articles (News)
		articles: {
			enabled: true,
		},
		// Enable Help Center
		help_center: {
			enabled: true,
		},
	});
};

const Frameless = lazy(() => import('layouts/Framesless'));
const AuthLayout = lazy(() => import('layouts/Auth'));
const AdminLayout = lazy(() => import('layouts/Admin'));
const ClientLayout = lazy(() => import('layouts/Client'));
const SettingsLayout = lazy(() => import('layouts/Settings'));
const PrivateRoute = lazy(() => import('Authorization'));
const AboutLayout = lazy(() => import('layouts/About'));
const PublicLayout = lazy(() => import('layouts/Public'));

const hist = createBrowserHistory({ basename: '/' });

var API_KEY = '';
switch (api.currentEnv) {
	case 'production':
		API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_PROD;
		break;
	case 'dev':
		API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_LOCAL;
		break;
	default:
		API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_STAGING;
		break;
}
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
script.defer = true;
document.body.appendChild(script);
export const store = createStore();
let showPopup = readCookie('showRating');
!showPopup && createCookie('showRating', true, true, 604800, '/');

if (api.currentEnv === 'production') {
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0,
	});
}

const RootIndex = () => {
	// let width = useCurrentWidth();
	var client = readCookie('client', true);
	useEffect(() => {
		if (client) {
			const user = {
				id: get(client, 'id'),
				firstName: get(client, 'first_name'),
				lastName: get(client, 'last_name'),
				email: get(client, 'email'),
				contact: get(client, 'contact_no'),
				first_recharge_amount: get(
					client,
					'first_recharge_details.first_recharge_amount'
				),
				first_recharge_date: get(
					client,
					'first_recharge_details.first_recharge_date'
				),
				last_recharge_amount: get(
					client,
					'last_recharge_details.last_recharge_amount'
				),
				last_recharge_date: get(
					client,
					'last_recharge_details.last_recharge_date'
				),
				first_shipment_date: get(
					client,
					'shipment_details.first_shipment_date'
				),
				last_shipment_date: get(client, 'shipment_details.last_shipment_date'),
				last_login: get(client, 'last_login'),
			};
			initializeIntercom(user);
		}
	}, [client]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const utm_source = urlSearchParams.get('utm_source');
		const utm_pathname = urlSearchParams.get('utm_pathname');
		const utm_referrer = urlSearchParams.get('utm_referrer');
		const utm_medium = urlSearchParams.get('utm_medium');
		const utm_campaign = urlSearchParams.get('utm_campaign');
		const utm_content = urlSearchParams.get('utm_content');
		const utm_term = urlSearchParams.get('utm_term');

		const utmData = {};

		if (utm_source) {
			utmData.utm_source = utm_source;
		}
		if (utm_pathname) {
			utmData.utm_pathname = utm_pathname;
		}
		if (utm_referrer) {
			utmData.utm_referrer = utm_referrer;
		}
		if (utm_medium) {
			utmData.utm_medium = utm_medium;
		}
		if (utm_campaign) {
			utmData.utm_campaign = utm_campaign;
		}
		if (utm_content) {
			utmData.utm_content = utm_content;
		}
		if (utm_term) {
			utmData.utm_term = utm_term;
		}

		// Set cookie only if utmData object is not empty
		if (Object?.keys(utmData)?.length > 0) {
			createCookie(
				'UTM',
				JSON.stringify(utmData),
				true,
				30 * 24 * 60 * 60 * 1000,
				'/',
				true,
				true
			);
		}

		const defaultObj = {
			loggedInDashboard: false,
			loggedInInvoice: false,
			loggedInShipment: false,
			loggedInPassbook: false,
		};

		sessionStorage.setItem('loggedIn', JSON.stringify(defaultObj));
	}, []);
	return (
		<Provider store={store}>
			<SnackbarProvider
				maxSnack={3}
				preventDuplicate
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<ConnectedRouter history={hist}>
					<Router history={hist}>
						<Suspense fallback={<Loader />}>
							{/* <Loader /> */}
							<Error403 />
							<AssignCarrierPopup />

							<ScrollToTop />
							<Switch>
								<Route path="/auth" component={AuthLayout} />
								<Route path="/admin" component={AdminLayout} />
								<Route path="/about" component={AboutLayout} />
								<Route path="/p" component={PublicLayout} />
								<Route path="/frameless" component={Frameless} />
								<PrivateRoute path="/client">
									<ClientLayout />
								</PrivateRoute>
								<PrivateRoute path="/settings">
									<SettingsLayout />
								</PrivateRoute>
								<Redirect from="/" to="/client/dashboard" />
							</Switch>
						</Suspense>
					</Router>
				</ConnectedRouter>
			</SnackbarProvider>
		</Provider>
	);
};
ReactDOM.render(<RootIndex />, document.getElementById('root'));

// serviceWorker.unregister();
