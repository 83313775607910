import {
	SET_CLIENT_DETAILS,
	SET_USER,
	SET_CLIENT_PERMISSIONS,
	SET_CLIENT_TERM,
	SET_403_ERROR,
	SET_CONTACT_US_POPUP,
	SET_APPLIED_NEW_PRICING_PLAN,
	SET_DASHBOARD_STATS_MONTH,
	SET_CARRIER_SETTING_POPUP,
	SET_SECURE_SHIPMENT_DETAILS_LIST,
	SET_ZONE_UPLOADER_LIST,
} from 'actions/user';
import moment from 'moment';

let date = new Date();
let startDate = moment(date).startOf('month').format('YYYY-MM-DD');
let endDate = moment(date).endOf('month').format('YYYY-MM-DD');

const ACTION_HANDLERS = {
	[SET_CLIENT_DETAILS]: (state, action) => ({
		...state,
		client: action.payload,
	}),
	[SET_USER]: (state, action) => ({
		...state,
		userAuth: {
			...state.userAuth,
			...action.payload,
		},
	}),
	[SET_CLIENT_PERMISSIONS]: (state, action) => ({
		...state,
		permissions: action.payload,
	}),
	[SET_CLIENT_TERM]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_403_ERROR]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_CONTACT_US_POPUP]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_APPLIED_NEW_PRICING_PLAN]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_DASHBOARD_STATS_MONTH]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_CARRIER_SETTING_POPUP]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_SECURE_SHIPMENT_DETAILS_LIST]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[SET_ZONE_UPLOADER_LIST]: (state, action) => ({
		...state,
		...action.payload,
	}),
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	userAuth: {
		reAuth: false,
		showReAuthModal: false,
	},
	agreement_popup: false,
	carrier_setting_required: false,
	permissions: [],
	agreement_popup_data: {},
	error403: false,
	error403Data: {},
	contact_us_popup: false,
	applied_new_pricing_plan: false,
	daterange: {
		date_time_before: endDate,
		date_time_after: startDate,
	},
};

export default function userReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
}
