import { axiosInstance } from './apiInstance';
import { setShipments } from 'actions/shipment';
import { setUser } from 'actions/user';
import get from 'lodash/get';
import { reloadUser } from 'utils/functions';
import * as urls from './urls';
import { store } from '../index';

export const getDeliveryPartners = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getDeliveryPartnerList, {
				params: {
					all_records: true,
				},
			})
			.catch((err) => {
				if (err?.response?.status) {
					let errResponse = get(err, 'response.status', false);
					if (errResponse === 401) {
						reloadUser();
						dispatch(
							setUser({
								reAuth: false,
								showReAuthModal: false,
								requestType: 'get',
							})
						);
						window.location.reload();
					}
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getDPModeLists = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getModeList)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getShipments = (pageNo, filterVals, page_size) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getShipmentList, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getShipmentsFetch = (pageNo, filterVals, page_size) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getShipmentFetchList, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getStuckShipments = (pageNo, filterVals, page_size) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getStuckShipmentList, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getUpdatedShipments = (shipmentID) => {
	return (dispatch) => {
		return axiosInstance
			.get(`/shipments/${shipmentID}`)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getShipmentAmountCharged = (filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getShipmentAmountCharged, {
				params: {
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const addShipment = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createShipment, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editShipment = (obj, shipmentId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/shipments/${shipmentId}`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deleteShipment = (id) => {
	return (dispatch) => {
		return axiosInstance
			.delete(`/shipments/${id}`)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 204) {
					return true;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getDeliveryPartnerWarehouses = (
	id,
	fetchAll,
	client_id,
	searchVal
) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getWarehouseAssociationsList, {
				params: {
					delivery_partner: id || undefined,
					all_records: fetchAll || undefined,
					client: client_id || undefined,
					q: searchVal || undefined,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
const geturl = (misfor) => {
	let url = urls.downloadMIS;
	if (misfor == 'order') {
		url = urls.downloadOrderMis;
	}
	if (misfor == 'weight') {
		url = urls.downloadWeightDiscrepancy;
	}
	if (misfor == 'gm') {
		url = urls.downloadGrossMarginReport;
	}
	if (misfor == 'ndr') {
		url = urls.downloadNDRMis;
	}
	return url;
};

export const misDownload = (queryParams, misfor = 'shipment') => {
	queryParams['emails'] = store.getState().shipment.add_on_emails.toString();
	return (dispatch) => {
		return axiosInstance
			.get(geturl(misfor), {
				params: {
					...queryParams,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const stuckShipmentDownload = (queryParams) => {
	queryParams['emails'] = store.getState().shipment.add_on_emails.toString();
	return (dispatch) => {
		return axiosInstance
			.get('/download_stuck_shipment', {
				params: {
					...queryParams,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getShipmentTrackDetails = (id) => {
	return () => {
		return axiosInstance
			.get(`/shipment/track/${id}`)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					// reloadUser()
					// dispatch(setUser({reAuth: false, showReAuthModal: false, requestType: 'get'}))
					// window.location.reload()
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const calculateShipment = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.getShipmentCharges, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getPickuptime = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.getDPPickupAvailability, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
// Orders APIs

export const retryShipment = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.retryShipment, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getOrders = (pageNo, page_size, filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getOrderList, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getUpdatedOrders = (orderId) => {
	return (dispatch) => {
		return axiosInstance
			.get(`/orders/${orderId}`)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const createOrder = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createOrder, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editOrder = (obj, orderId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/orders/${orderId}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const orderShipmentAssociation = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.createShipmentOrderAssociation, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editOrderAssociationShipment = (obj, shipmentId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/order_shipment_association/${shipmentId}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editAppointmentShipment = (obj, shipmentId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(`/add_appointment/${shipmentId}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const bulkUploadOrders = (fileName, url = 'uploads/files/') => {
	let reqObj = {
		file_path: url,
		file_name: fileName,
		emails: store.getState().shipment.add_on_emails.toString(),
	};
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.bulkCreateShipments, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const bulkUploadWarehouse = (fileName, url = 'uploads/files/') => {
	let reqObj = {
		file_path: url,
		file_name: fileName,
		emails: store.getState().shipment.add_on_emails.toString(),
	};
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.bulkCreateWarehouse, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const mapPickupShipment = (pickupId, shipmentId) => {
	let reqObj = {
		pickup_id: pickupId,
		shipment_id: shipmentId,
	};
	let body = JSON.stringify(reqObj);
	return (dispatch) => {
		return axiosInstance
			.put(urls.mapPickupToShipment, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const deleteOrder = (id) => {
	return (dispatch) => {
		return axiosInstance
			.delete(`/orders/${id}`)
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'delete',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 204) {
					return true;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getShipmentCounts = (clientId, daterange = {}) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getShipmentCounts, {
				params: {
					client_id: clientId,
					...daterange,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const toPayShipmentList = (pageNo, filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getToPayShipments, {
				params: {
					page: pageNo || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const weightDiscrepancieList = (pageNo, filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getWeightDiscrepancies, {
				params: {
					page: pageNo || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const raiseDispute = (obj, id) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(urls.getWeightDiscrepancies + `${id}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const downloadRemittance = (queryParams) => {
	let add_on_emails = store.getState().shipment.add_on_emails.toString();
	return (dispatch) => {
		return axiosInstance
			.get(urls.downloadRemittance, {
				params: {
					add_on_emails,
					...queryParams,
				},
			})
			.catch((err) => {
				if (err.response.status === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getNdrShipments = (pageNo, filterVals, page_size) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getNdrShipmentList, {
				params: {
					page: pageNo || undefined,
					page_size: page_size || undefined,
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					dispatch(setShipments(response.data));
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const editNDRShipment = (obj, ndrShipmentId) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(urls.getNdrShipmentList + `${ndrShipmentId}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

// abd request api

export const getAbdRequest = (filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getAbdRequestApi, {
				params: {
					...filterVals,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const postUploadAbdRequest = (
	fileName,
	typeOfEvent,
	url = 'uploads/files/abd_uploader/'
) => {
	let reqObj = {
		file_name: fileName,
		file_path: url,
		type_of_event: typeOfEvent,
	};
	let body = JSON.stringify(reqObj);

	return (dispatch) => {
		return axiosInstance
			.post(urls.UploadAbdRequest, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const postDownloadAbdRequest = (fieldQuery) => {
	let body = JSON.stringify(fieldQuery);
	return (dispatch) => {
		return axiosInstance
			.post(urls.postDownloadAbdRequest, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: true,
							showReAuthModal: true,
							requestType: 'post',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const putAbdRequestApi = (obj, order_id) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.put(urls.putAbdRequestApi + `${order_id}/`, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const postPoMultipleUpload = (obj) => {
	let body = JSON.stringify(obj);
	return (dispatch) => {
		return axiosInstance
			.post(urls.postPoMultipleUpload, body)
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({ reAuth: true, showReAuthModal: true, requestType: 'put' })
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getPricingPlanPopup = (clientId) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getPricingPlanPopup, {
				params: {
					client_id: clientId,
				},
			})
			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getPricingPlan = () => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.getPricingPlan)

			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};

export const getPricingPLanSaving = (filterVals) => {
	return (dispatch) => {
		return axiosInstance
			.get(urls.pricingPlanSaving, { params: { ...filterVals } })

			.catch((err) => {
				let errResponse = get(err, 'response.status', false);
				if (errResponse === 401) {
					reloadUser();
					dispatch(
						setUser({
							reAuth: false,
							showReAuthModal: false,
							requestType: 'get',
						})
					);
					window.location.reload();
				}
				throw err;
			})
			.then((response) => {
				if (response.status === 201 || response.status === 200) {
					return response.data;
				} else {
					let err = new Error(response.data.msg);
					throw err;
				}
			});
	};
};
